/*
    BG style based on HTML 5 Up Dimensions Template
*/

#form {
    z-index: 3;
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
}

#bg {
    -moz-transform: scale(1.0);
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
    -webkit-backface-visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

#bg:before, #bg:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#bg:before {
    -moz-transition: background-color 2.5s ease-in-out;
    -webkit-transition: background-color 2.5s ease-in-out;
    -ms-transition: background-color 2.5s ease-in-out;
    transition: background-color 2.5s ease-in-out;
    -moz-transition-delay: 0.75s;
    -webkit-transition-delay: 0.75s;
    -ms-transition-delay: 0.75s;
    transition-delay: 0.75s;
    /*background-image: linear-gradient(to top, rgba(19, 21, 25, 0.5), rgba(19, 21, 25, 0.5)), url("./bg_overlay.png");*/
    background-color: rgba(0, 0, 0, 0.33);
    background-size: auto, 256px 256px;
    background-position: center, center;
    background-repeat: no-repeat, repeat;
    z-index: 2;
}

#bg:after {
    -moz-transform: scale(1.125);
    -webkit-transform: scale(1.125);
    -ms-transform: scale(1.125);
    transform: scale(1.125);
    -moz-transition: -moz-transform 0.325s ease-in-out, -moz-filter 0.325s ease-in-out;
    -webkit-transition: -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out;
    -ms-transition: -ms-transform 0.325s ease-in-out, -ms-filter 0.325s ease-in-out;
    transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out;
    background-image: url("./bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

#footer {
    z-index: 3;
    position: absolute;
    color: white;
    bottom: 2%;
    left: 5%;
}

#footer .copyright {
    font-size: 0.6rem;
    opacity: 0.60;
    margin-bottom: 0;
}