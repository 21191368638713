.App {
  text-align: left;
  padding: 1% 2.5% 2.5% 2.5%;
}

.App-logo {
  height: 50px;
}

.App-header {
  height: 65px;
  padding: 20px;
}

.App-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8em;
  line-height: 0.1em;
  letter-spacing: 0.1em;
}

.App-intro {
  font-size: large;
}

/*
  These table styles are required here becaue CladUi only styles components with
  a '.clad' class. The tables generated from Markdown do not have that class.
*/

table {
  color: black;
}

tbody tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.4);
}

tbody tr:nth-child(even) {
  background-color: rgba(25, 123, 206, 0.2);
}

tbody tr {
  background-color: black;
}

table {
  background-color: rgba(255, 255, 255, 0.4);
}
