.highlight {
    width: 100%;
    position: relative;
}

.Bill {
  background-color: orange;
  color: rgba(0, 0, 0, 0.75);
}

.Charity {
  background-color: brown;
  color: rgba(255, 255, 255, 0.8);
}

.Income {
  background-color: green;
  color: rgba(255, 255, 255, 0.8);
}

.Retirement {
  background-color: darkblue;
  color: rgba(255, 255, 255, 0.8);
}

.Misc {
  background-color: pink;
  color: rgba(0, 0, 0, 0.8);
}

.Savings {
  background-color: blue;
  color: rgba(255, 255, 255, 0.8);
}

.Taxes {
  background-color: purple;
  color: rgba(255, 255, 255, 0.8);
}

.Unallocated {
  background-color: black;
  color: rgba(255, 255, 255, 0.8);
}
